import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";

type FilesContextType = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handlePhotoTaken: (blob: Blob) => void;
  isCameraActive: boolean;
  setIsCameraActive: (active: boolean) => void;
};

const FilesContext = createContext<FilesContextType | undefined>(undefined);

export const FilesProvider = ({ children }: { children: ReactNode }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isCameraActive, setIsCameraActive] = useState(false);

  const handlePhotoTaken = useCallback(
    (blob: Blob) => {
      const file = new File([blob], `photo-${files.length + 1}.jpg`, { type: blob.type });
      setFiles((prevFiles) => [...prevFiles, file]);
    },
    [files],
  );

  return (
    <FilesContext.Provider value={{ files, setFiles, handlePhotoTaken, isCameraActive, setIsCameraActive }}>
      {children}
    </FilesContext.Provider>
  );
};

export const useFiles = () => {
  const context = useContext(FilesContext);
  if (!context) {
    throw new Error("useFiles must be used within a FilesProvider");
  }
  return context;
};
